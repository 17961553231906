import { Injectable } from '@angular/core'
import { StorageService } from './abstract/storage.service'

export type LocalStorageKey =
  | 'boosterAuthToken'
  | 'boosterUserId'
  | 'user'
  | 'showExpandedTable'
  | 'abacusFilteringPreferences'

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends StorageService<LocalStorageKey> {
  constructor() {
    super(window.localStorage)
  }
}
