type SupportedValues =
  | number
  | string
  | boolean
  | Record<string | number, unknown>
  | unknown[]

export abstract class StorageService<T extends string> {
  private storage

  constructor(storage: Storage) {
    this.storage = storage
  }

  setItem(key: T, value: SupportedValues) {
    this.storage.setItem(key, JSON.stringify(value))
  }

  getItem<G = SupportedValues>(key: T): G {
    const item = this.storage.getItem(key)

    // TODO: before the storage service was introduced some items were not stringified
    // try-catch is needed for those entries because JSON.parse will fail
    try {
      return JSON.parse(item as any)
    } catch (_) {
      return item as any
    }
  }

  removeItem(key: T) {
    this.storage.removeItem(key)
  }

  clear() {
    this.storage.clear()
  }
}
