import { Injectable } from '@angular/core'
import { StorageService } from './abstract/storage.service'

export type RmdSessionStorageKey =
  | 'boosterAuthToken'
  | 'boosterUserId'
  | 'user'
  | 'impersonatedById'

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends StorageService<RmdSessionStorageKey> {
  constructor() {
    super(window.sessionStorage)
  }
}
